<template>
      <el-row :gutter="20" type="flex" justify="center">
        <el-col :span="20" :offset="2" :xs="24" :sm="24" :md="24" :lg="18" :xl="14">
          <custom-header title="Дэлгэрэнгүй"/>
          <loader :active="isLoading"/>
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
                <div class="panel-item">
                  <header>Үндсэн мэдээлэл</header>
                  <div class="panel-item border-top">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <span><strong>Монгол гарчиг:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{banner.title}}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Англи гарчиг:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{banner.title_eng}}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Монгол тайлбар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{banner.description}}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="banner.description_eng !== ''">
                      <el-col :span="8">
                        <span><strong>Англи тайлбар:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{banner.description_eng}}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Сурталчилгааны төлөв:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <el-tag v-if="banner.status === 1" type="success" effect="dark">Active</el-tag>
                        <el-tag v-else-if="banner.status === 0" type="warning" effect="dark">Expired</el-tag>
                        <el-tag v-else type="warning" effect="dark">{{banner.status}}</el-tag>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Хуудас, байрлал, төрөл</header>
                  <div class="panel-item border-top">
                    <el-row :gutter="20" v-if="bannerPage.find(item => item.value === banner.page)">
                      <el-col :span="8">
                        <span><strong>Сурталчилгаа харуулах хуудас:</strong></span>
                      </el-col>
                      <el-col :span="12" >
                        {{ bannerPage[bannerPage.findIndex(item => item.value === banner.page)].label }}
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="Object.prototype.hasOwnProperty.call(banner, 'categoryName, productName, brandName')">
                      <el-col :span="8">
                        <span v-if="Object.prototype.hasOwnProperty.call(banner, 'categoryName')"><strong>Ангилалын нэр:</strong></span>
                        <span v-if="Object.prototype.hasOwnProperty.call(banner, 'productName')"><strong>Бүтээгдэхүүны нэр:</strong></span>
                        <span v-if="Object.prototype.hasOwnProperty.call(banner, 'brandName')"><strong>Брэндийн нэр:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{banner.categoryName}}</span>
                        <span>{{banner.productName}}</span>
                        <span>{{banner.brandName}}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="bannerPositionType.find(item => item.value === banner.position)">
                      <el-col :span="8">
                        <span><strong>Сурталчилгааны байрлал:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ bannerPositionType[bannerPositionType.findIndex(item => item.value === banner.position)].label }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="bannerType.find(item => item.value === banner.type)">
                      <el-col :span="8">
                        <span><strong>Сурталчилгааны төрөл:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <span>{{ bannerType[bannerType.findIndex(item => item.value === banner.type)].label }}</span>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20" v-if="banner.type === 'WEB_LINKING'">
                      <el-col :span="8">
                        <span><strong>Шилжих веб хуудас url:</strong></span>
                      </el-col>
                      <el-col :span="12" v-for="item in banner.pass_prop" :key="item.value">
                        {{item.web_url}}
                      </el-col>
                    </el-row>
                    <div v-if="banner.type === 'POP_UP'">
                    <el-row :gutter="20" class="mt20" >
                      <el-col :span="8">
                        <span><strong>Pop Up зураг:</strong></span>
                      </el-col>
                      <el-col :span="12" v-for="item in banner.pass_prop" :key="item.value">
                       <div class="image-holder">
                          <img :src="item.pop_up_image_url">
                       </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Pop Up тайлбар:</strong></span>
                      </el-col>
                      <el-col :span="12" v-for="item in banner.pass_prop" :key="item.value">
                        {{item.pop_up_description}}
                      </el-col>
                    </el-row>
                    </div>
                    <el-row :gutter="20" class="mt20" v-if="banner.sorNumber !== ''">
                      <el-col :span="8">
                        <span><strong>SortNumber:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        {{banner.sortNumber}}
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг, огноо</header>
                  <div class="panel-item border-top">
                    <el-row :gutter="20">
                      <el-col :span="8">
                        <span><strong>Зураг:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <div class="image-holder">
                          <img :src="banner.image_url">
                        </div>
                      </el-col>
                    </el-row>
                     <el-row :gutter="20" class="mt20" v-if="banner.image_url_eng !== ''">
                      <el-col :span="8">
                        <span><strong>Зураг англи:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        <div class="image-holder">
                          <img :src="banner.image_url_eng">
                        </div>
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Эхлэх огноо:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        {{banner.start_date}}
                      </el-col>
                    </el-row>
                    <el-row :gutter="20" class="mt20">
                      <el-col :span="8">
                        <span><strong>Дуусах огноо:</strong></span>
                      </el-col>
                      <el-col :span="12">
                        {{banner.end_date}}
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
           <el-row :gutter="20">
            <el-col :span="20">
              <div class="text-right">
                <el-button type="default" @click="goBack"
                  >Буцах
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
</template>

<script>
import services from '../../../helpers/services'
import CustomHeader from '../../../components/common/customHeader'
import Loader from '../../../components/common/loader'
export default {
  name: 'bannerDetail',
  components: {
    CustomHeader,
    Loader
  },

  data () {
    return {
      isLoading: false,
      banner: [],

      bannerPage: [{
        value: 'home',
        label: 'Нүүр хуудас'
      }, {
        value: 'product',
        label: 'Бүтээгдэхүүн'
      }, {
        value: 'brand',
        label: 'Брэнд'
      }, {
        value: 'brands',
        label: 'Брэндүүд'
      }, {
        value: 'category',
        label: 'Категори'
      }, {
        value: 'special',
        label: 'Онцлох'
      }, {
        value: 'coupon',
        label: 'Купон'
      }, {
        value: 'co_working',
        label: 'Хамтран ажиллах'
      }, {
        value: 'new',
        label: 'Шинэ'
      }, {
        value: 'sale',
        label: 'Хямдрал'
      }, {
        value: 'mongolian-brand',
        label: 'Монгол брэнд'
      }, {
        value: 'finish',
        label: 'Finish хуудас'
      }],

      bannerPositionType: [{
        value: 'left-top',
        label: 'Зүүн дээр'
      }, {
        value: 'right-top',
        label: 'Баруун дээр'
      }, {
        value: 'right-bottom',
        label: 'Баруун доор'
      }, {
        value: 'top',
        label: 'Дээр'
      }, {
        value: 'middle-right',
        label: 'Баруун дунд'
      }, {
        value: 'middle-left',
        label: 'Зүүн дунд'
      }, {
        value: 'middle',
        label: 'Дунд'
      }],

      bannerType: [{
        value: 'IMAGE',
        label: 'Зураг'
      }, {
        value: 'WEB_LINKING',
        label: 'Веб холбоостой'
      }, {
        value: 'DEEP_LINKING',
        label: 'Веб дотоод холбоостой'
      }, {
        value: 'POP_UP',
        label: 'POP UP холбоостой'
      }]
    }
  },

  mounted () {
    if (this.$route.params.id) {
      this.getOneBanner(this.$route.params.id)
    }
  },

  methods: {
    getOneBanner (id) {
      this.isLoading = true
      services.getOneBanner(id).then(response => {
        if (response.status === 'success') {
          this.banner = response.data
          this.isLoading = false
        }
      })
    },

    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style>

</style>
